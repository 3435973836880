.Ingredient {
  width: 100px;
  padding: 40px 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  user-select: none;
  margin: 0 4px;
  animation: appear 0.4s forwards;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
