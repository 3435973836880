* {
  box-sizing: border-box;
}

html,
body,
#game {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  background-color: #ecefed;
}

img {
  max-width: 100%;
  display: block;
}
